@use 'src/styles/_variables.scss' as *;

header {
    width: 100%;
    height: 54px;
    background: white;
    padding: 0 25px;
}

.headerContainer {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerLeft,
.headerRight {
    display: flex;
    align-items: center;
    .headerLinks {
        display: flex;
        align-items: center;
        a {
            font-size: 17px;
            font-weight: 300;
            transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
            outline: none;
            margin: 0 0 0 50px;
            &:link,
            &:visited {
                color: #000000;
                text-decoration: none;
            }
            &:hover,
            &:focus {
                fill: $primary-color;
                color: $primary-color;
            }
            &.headerLink {
                margin: 0 0 0 40px;
                padding: 5px;
                border: 1px solid #ffffff;
                &:hover,
                &:focus,
                &.active {
                    border-color: $primary-color;
                }
            }
        }
    }
}

.verticalRule {
    background-color: rgba(0, 0, 0, 0.173);
    height: 32px;
    width: 1px;
    margin: 0 0 0 50px;
}

.drawerOpener {
    cursor: pointer;
}
