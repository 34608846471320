@use 'src/styles/_variables.scss' as *;

html {
    font-family: 'Roboto', sans-serif;
    width: 100%;
}

body {
    font-size: 13px;
    background: $background-color;
    width: 100%;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

p {
    margin: 0;
}

#root {
    width: 100%;
}

a:link,
a:visited,
a:hover {
    color: $primary-color;
    text-decoration: underline;
}
