.homeTechnologies {
    p {
        font-size: 30px;
        text-align: center;
        padding: 100px 16px 0 16px;
        max-width: 800px;
        margin: 0 auto;
    }
}

.allTech {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1280px;
    margin: 0 auto;
    padding: 50px 16px 50px 16px;
}

.resumeLinkContainer {
    display: flex;
    justify-content: center;
    padding: 0 25px 100px 25px;
}

@media (max-width: 768px) {
    .homeTechnologies {
        p {
            font-size: 20px;
            padding: 50px 16px 0 16px;
        }
    }
    .allTech {
        padding: 25px 16px 25px 16px;
    }
    .resumeLinkContainer {
        padding: 0 25px 50px 25px;
    }
}
