$primary-color: #2e7d32;
$dark-primary-color: #346b37;
$background-color: #fafafa;
$dark-gray: #e4e4e4;
$charcoal: #4f4f4f;

$error-color: #f44336;
$success-color: #4caf50;
$warning-color: #ff8800;
$info-color: #2196f3;
