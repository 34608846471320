@use 'src/styles/_variables.scss' as *;

.resumeContainer {
    padding: 100px 25px;
    line-height: 1.5;
    & > * {
        max-width: 1280px;
        margin: 0 auto;
    }
}

.resumeSection {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
    padding: 0 0 50px 0;
    .sectionLeft {
        max-width: 300px;
        width: 100%;
        text-align: right;
        h1 {
            margin: 0;
            font-weight: 300;
            font-size: 30px;
            padding: 0 100px 25px 0;
        }
    }
    .sectionRight {
        max-width: 800px;
        width: 100%;
    }
}

.sideBySide {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    & > :first-child {
        margin: 0 100px 0 0;
    }
    h2 {
        font-weight: 300;
        margin: 10px 0;
        color: $primary-color;
    }
}

ul {
    font-size: 16px;
    li {
        list-style-position: outside;
        padding-left: 1em;
        text-indent: -1.3em;
        list-style: none;
        &::before {
            color: $primary-color;
            content: '\2022';
            font-size: 1em;
            padding-right: 1em;
            position: relative;
            top: 0em;
        }
    }
}

.resumeExperience,
.resumeEducation {
    margin: 0 0 30px 0;
    .resumeHeader {
        margin: 10px 0 0 0;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        h2 {
            font-weight: 300;
            margin: 0 10px 0 0;
            font-size: 23px;
            color: $primary-color;
        }
        h3 {
            font-size: 18px;
            font-weight: 300;
            margin: 0;
        }
    }
    h4 {
        margin: 0 0 10px 0;
        font-weight: 300;
        font-size: 14px;
        color: $charcoal;
    }
    p {
        font-size: 16px;
    }
}

.resumeDownloadContainer {
    background: url('/images/backgrounds/resume-coding.jpg') no-repeat center
        center;
    background-size: cover;
    height: 300px;
    position: relative;
    .resumeDownloadInner {
        padding: 100px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.1);
        transition: background 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
        color: #ffffff;
        font-weight: 300;
        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.3);
        }
        h2 {
            text-align: center;
            font-weight: 300;
            font-size: 40px;
            color: #ffffff;
            width: 300px;
            margin: 0;
        }
        .resumeDownload {
            max-width: 500px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
        }
    }
}

.resumeContactMe {
    background: url('/images/backgrounds/green-telephone.jpg') no-repeat center
        center;
    background-size: cover;
    width: 100%;
    height: 500px;
    position: relative;
}

.contactBannerOverlay {
    padding: 100px 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: #ffffff;
    font-weight: 300;
    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 0.7);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    h1 {
        font-size: 50px;
        font-weight: 300;
        margin: 0;
    }
    p {
        font-size: 25px;
        max-width: 500px;
        text-align: center;
        line-height: 1.5;
    }
    a {
        padding: 20px 40px;
        font-size: 20px;
    }
}

@media (max-width: 1000px) {
    .resumeSection {
        .sectionLeft {
            max-width: unset;
            text-align: center;
            h1 {
                padding: 0 0 25px 0;
            }
        }
    }
}

@media (max-width: 500px) {
    .resumeContainer {
        padding: 50px 25px;
    }
    .resumeDownloadContainer {
        padding: 40px 25px;
        .resumeDownloadInner {
            background: rgba(0, 0, 0, 0.4);
            &:hover,
            &:focus {
                background: rgba(0, 0, 0, 0.6);
            }
            h2 {
                font-size: 30px;
            }
        }
    }
    .resumeContactMe {
        height: 400px;
    }
    .contactBannerOverlay {
        padding: 75px 25px;
        h1 {
            font-size: 35px;
        }
        p {
            font-size: 20px;
        }
        a {
            padding: 15px 30px;
            font-size: 15px;
        }
    }
    .sideBySide > :first-child {
        margin: 0;
    }
}

@media (max-width: 350px) {
    .contactBannerOverlay {
        h1 {
            font-size: 30px;
        }
        a {
            padding: 15px 20px;
            font-size: 15px;
        }
    }
    .resumeDownloadContainer {
        .resumeDownloadInner {
            h2 {
                font-size: 25px;
            }
        }
    }
}
