@use 'src/styles/_mixins.scss' as *;

.techContainer {
    margin: 10px;
    text-align: center;
    padding: 10px;
    transition:
        background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 50px;
    box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    color: #000000;
    a:link,
    a:visited,
    a:hover {
        text-decoration: none;
        color: inherit;
    }
    img {
        width: 150px;
        height: 150px;
    }
    h2 {
        font-weight: normal;
        margin: 0;
        padding: 20px;
        color: inherit;
    }
    &:hover,
    &:focus {
        @include rgba_primary(0.7);
        cursor: pointer;
        color: #ffffff;
        outline: none;
    }
}

@media (max-width: 500px) {
    .techContainer {
        img {
            width: 100px;
            height: 100px;
        }
        h2 {
            padding: 10px 5px;
            font-size: 17px;
        }
    }
}
