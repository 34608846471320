@use 'src/styles/_variables.scss' as *;

.buttonLink {
    display: flex;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: $primary-color;
    padding: 15px 25px;
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    border: 2px solid $primary-color;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: 1px;
    background: none;
    outline: none;
    cursor: pointer;
    fill: $primary-color;
    & i {
        margin-right: 5px;
    }
    & svg {
        margin-right: 10px;
    }
    &:link,
    &:visited {
        color: $primary-color;
        text-decoration: none;
        fill: $primary-color;
    }
    &:hover,
    &:focus {
        background: $primary-color;
        color: #ffffff;
        fill: #ffffff;
        text-decoration: none;
    }
    &.inverse {
        background: $primary-color;
        color: #ffffff;
        fill: #ffffff;
        &:hover,
        &:focus {
            background: none;
            color: $primary-color;
            fill: $primary-color;
            text-decoration: none;
        }
    }
    &.white {
        border: 2px solid #ffffff;
        color: #ffffff;
        fill: #ffffff;
        &:link,
        &:visited {
            color: #ffffff;
            text-decoration: none;
            fill: #ffffff;
        }
        &:hover,
        &:focus {
            background: #ffffff;
            color: #000000;
            fill: #000000;
            text-decoration: none;
        }
        &.inverse {
            background: #ffffff;
            color: #000000;
            fill: #000000;
            &:hover,
            &:focus {
                background: none;
                color: #ffffff;
                fill: #ffffff;
                text-decoration: none;
            }
        }
    }
}

.roundButtonLink {
    width: 200px;
    height: 200px;
    transition:
        background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: $primary-color;
    padding: 15px 25px;
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    border: 2px solid $primary-color;
    text-decoration: none;
    font-weight: 400;
    letter-spacing: 1px;
    background: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    & > i {
        font-size: 100px;
    }
    &:link,
    &:visited {
        color: $primary-color;
        text-decoration: none;
    }
    &:hover,
    &:focus {
        background: $primary-color;
        color: #ffffff;
        text-decoration: none;
    }
    &.inverse {
        background: $primary-color;
        color: #ffffff;
        &:hover,
        &:focus {
            background: none;
            color: $primary-color;
        }
    }
}
