.contactMeContainer {
    background: url('/images/backgrounds/orange-envelope.jpg') no-repeat center
        center;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 100px);
    min-height: 700px;
    max-height: 900px;
    position: relative;
}

.overlay {
    padding: 100px 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: #ffffff;
    font-weight: 300;
    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 0.7);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    h1 {
        font-size: 50px;
        font-weight: 300;
        margin: 0;
    }
    p {
        font-size: 25px;
        max-width: 500px;
        text-align: center;
        line-height: 1.5;
    }
    a {
        padding: 20px 40px;
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .contactMeContainer {
        height: calc(100vh - 200px);
    }
    .overlay {
        padding: 75px 25px;
        h1 {
            font-size: 40px;
        }
        p {
            font-size: 20px;
        }
        a {
            padding: 15px 30px;
            font-size: 15px;
        }
    }
}
