@use 'src/styles/_variables.scss' as *;

.homeProjects {
    background: $dark-gray;
    padding: 100px 25px;
    h1 {
        font-weight: 300;
        font-size: 40px;
        padding: 0 100px;
    }
    & > * {
        max-width: 1280px;
        margin: 0 auto;
    }
}

.projectContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 25px 0;
}

.projectsLinkContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 100px;
}

@media (max-width: 768px) {
    .homeProjects {
        h1 {
            padding: 0 10px;
        }
    }
}

@media (max-width: 500px) {
    .homeProjects {
        padding: 25px;
        h1 {
            font-size: 35px;
            text-align: center;
        }
    }
    .projectsLinkContainer {
        justify-content: center;
        padding: 0 10px;
    }
}
