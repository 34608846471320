@use 'src/styles/_variables.scss' as *;

footer {
    width: 100%;
    padding: 50px 16px;
    text-align: center;
    background-color: #ffffff;
    p {
        color: $charcoal;
        line-height: 1.4;
        &.footerName {
            font-size: 25px;
            padding: 15px 0 15px 0;
        }
    }
}

nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0 15px 0;
    a {
        font-size: 18px;
        font-weight: 300;
        margin: 5px 20px;
        transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
        &:link,
        &:visited {
            color: #000000;
            text-decoration: none;
        }
        &:hover,
        &:focus {
            color: $primary-color;
            fill: $primary-color;
            text-decoration: none;
        }
    }
}

@media (max-width: 500px) {
    footer {
        padding: 25px 16px;
        p {
            &.footerName {
                font-size: 20px;
            }
        }
    }
}
