.snackbarMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    font-weight: 300;
    font-size: 20px;
    & > * {
        margin: 0 15px 0 0;
    }
}

.snackbarClose {
    cursor: pointer;
    display: flex;
}
