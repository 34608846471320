@use 'src/styles/_variables.scss' as *;

.leadContainer {
    background: url('/images/backgrounds/portfolio-agenda.jpg') no-repeat center
        center;
    background-size: cover;
    position: relative;
    height: 200px;
}

.leadText {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    p {
        font-size: 25px;
        padding: 0 50px;
        font-weight: 300;
        line-height: 1.4;
        color: #ffffff;
        max-width: 1280px;
    }
}

.portfolioSection {
    padding: 100px 25px;
    &.completedProjects {
        background: $dark-gray;
    }
    h1 {
        font-weight: 300;
        font-size: 40px;
        padding: 0 100px;
    }
    & > * {
        max-width: 1280px;
        margin: 0 auto;
        text-align: center;
    }
}

.portfolioProjectContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 80px 0 0 0;
}

@media (max-width: 768px) {
    .portfolioSection {
        h1 {
            padding: 0 10px;
        }
    }
    .portfolioProjectContainer {
        padding: 25px 0 0 0;
    }
}

@media (max-width: 500px) {
    .leadText {
        p {
            font-size: 20px;
            padding: 0 25px;
        }
    }
    .portfolioSection {
        padding: 25px;
        h1 {
            font-size: 35px;
        }
    }
}
