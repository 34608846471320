@use 'src/styles/_variables.scss' as *;

.notFound {
    text-align: center;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.errorSection {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    h1 {
        margin: 0;
        padding: 0 2px;
        font-size: 80px;
    }
    .small {
        margin: 0;
        padding: 0 2px;
        font-size: 25px;
    }
}

.description,
.contact {
    text-align: center;
    font-size: 16px;
}

.description {
    margin: 30px 0 0 0;
}

.contact {
    margin: 10px 0 0 0;
}

.spanLink {
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 768px) {
    .errorSection {
        h1 {
            font-size: 50px;
        }
        .small {
            font-size: 15px;
        }
    }
    .description,
    .contact {
        font-size: 14px;
    }
}
