.appComponents {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    position: relative;
}

section.routeSection {
    &.fadeExit {
        display: none;
    }
}

.fadeEnter {
    opacity: 0.01;
    z-index: 1;
    &.fadeEnterActive {
        opacity: 1;
        transition: opacity 250ms ease-in;
    }
}

.fadeExit {
    opacity: 1;
    &.fadeExitActive {
        opacity: 0.01;
        transition: opacity 250ms ease-in;
    }
}
