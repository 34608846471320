@use 'src/styles/_variables.scss' as *;
@use 'src/styles/_mixins.scss' as *;

.contactTopContainer {
    background: $dark-gray;
    padding: 50px 25px;
    & > * {
        max-width: 1280px;
        margin: 0 auto;
        text-align: center;
    }
}

.contactMap {
    width: 100%;
    height: 50vh;
    position: relative;
    background: url('/images/backgrounds/astoria-map.png') no-repeat center
        center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-filter: grayscale(75%);
    filter: grayscale(75%);
}

.contactChooser {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .contactOption {
        margin: 50px 100px;
        min-width: 350px;
        padding: 25px 50px;
        background: $background-color;
        box-shadow:
            rgba(0, 0, 0, 0.12) 0px 1px 6px,
            rgba(0, 0, 0, 0.12) 0px 1px 4px;
        transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
        cursor: pointer;
        & > i {
            font-size: 150px;
        }
        .contactText {
            font-size: 25px;
            font-weight: 300;
            margin: 20px 0;
            line-height: 1.4;
        }
        &:hover,
        &:focus {
            @include rgba_primary(0.8);
            color: white;
        }
    }
}

.contactDialog {
    & > * {
        max-width: 1280px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }
    &.center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        align-items: center;
    }
    .dialogExit {
        display: flex;
        justify-content: flex-end;
        & > i {
            font-size: 30px;
            cursor: pointer;
            &:focus {
                border: none;
                outline: none;
            }
        }
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0 0 0;
    }
    p {
        font-size: 30px;
        font-weight: 300;
        line-height: 1.4;
        strong {
            font-weight: 400;
            font-size: 32px;
        }
    }
    .recaptchaContainer {
        margin: 15px;
    }
    .dialogButtonContainer {
        margin: 5px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &.phone {
            margin: 0;
            padding: 25px 0;
        }
    }
}

.phoneWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    & > span {
        margin: 0 0 0 8px;
    }
}

@media (max-width: 500px) {
    .contactMap {
        height: 35vh;
    }
    .contactChooser {
        .contactOption {
            min-width: 250px;
            & > i {
                font-size: 100px;
            }
            .contactText {
                font-size: 20px;
            }
        }
    }
    .contactDialog {
        form {
            padding: 25px 0 0 0;
        }
        p {
            font-size: 25px;
            strong {
                font-size: 27px;
            }
        }
    }
}
