@use 'src/styles/_variables.scss' as *;

.formGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.formGroup textarea {
    padding: 10px 15px;
    max-width: 400px;
    width: 100%;
    margin: 10px;
    font-size: 15px;
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.6);
    font-weight: 300;
    outline: none;
    color: #000000;
    line-height: normal;
    resize: vertical;
    min-height: 75px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:focus,
    &.error:focus {
        border-color: $primary-color;
        color: $primary-color;
    }
    &.error {
        border-color: $error-color;
        color: $error-color;
    }
}

.errorAlert {
    width: 100%;
    max-width: 400px;
    color: $error-color;
    text-align: left;
    margin: 0 10px;
    font-size: 12px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
