@use 'src/styles/_variables.scss' as *;
@use 'src/styles/_mixins.scss' as *;

.projectDialog {
    position: relative;
    & > * {
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }
    &.center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        align-items: center;
    }
    .dialogBanner {
        height: 200px;
        width: 100%;
        position: relative;
        .dialogBannerOverlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.1);
        }
    }
    h2 {
        margin: 15px 0 0 0;
        font-size: 35px;
        text-align: left;
        font-weight: normal;
        color: $primary-color;
    }
    h3 {
        margin: 5px 0;
        text-align: left;
        color: $charcoal;
        font-size: 14px;
        font-weight: normal;
    }
    p {
        text-align: left;
        font-size: 18px;
        color: $charcoal;
        line-height: 1.3;
    }
}

.exitContainer {
    position: absolute;
    top: 27px;
    right: 31px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .drawerExit {
        width: 100%;
        display: flex;
        justify-content: center;
        & > i {
            font-size: 25px;
            cursor: pointer;
            &:focus {
                border: none;
                outline: none;
            }
        }
    }
}

.activeContainer {
    position: absolute;
    top: 27px;
    left: 31px;
    width: auto;
    height: auto;
    border-radius: 15px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    .activeDot {
        width: 15px;
        height: 15px;
        border-radius: 50px;
        background: $success-color;
    }
    .activeDot.inactive {
        background: $error-color;
    }
    h4 {
        margin: 0 0 0 5px;
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        color: $success-color;
        text-transform: uppercase;
    }
    h4.inactive {
        color: $error-color;
    }
}

.projectLinks {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 15px 0 0 0;
    a {
        padding: 10px 20px;
        font-size: 13px;
    }
}

.badgeContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
    .techBadge {
        @include rgba_primary(0.6);
        padding: 5px;
        color: #ffffff;
        font-weight: 300;
        margin: 0 5px 5px 0;
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .projectDialog {
        h2 {
            font-size: 30px;
        }
    }
    .projectLinks {
        justify-content: center;
    }
}
